/*! purgecss start ignore */
.umbraco-forms-form {
  text-align: left;
}

.umbraco-forms-hidden {
	display: none;
}

.umbraco-forms-field {
  margin-bottom: 20px;
}

.umbraco-forms-label {
  display: block;
  margin-bottom: 5px;
}

.umbraco-forms-indicator {
  @include font-size(nth($font-sizes, 3));

  color: _($colors, 'brand-2');
  display: inline-block;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-weight: bold;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.radiobuttonlist {

  input {
    display: none;
  }

  label {
    cursor: pointer;
    display: block;
    margin-bottom: 5px;
    padding-left: 30px;
    position: relative;

    &::after,
    &::before {
      border: 2px solid $color-border;
      border-radius: 50%;
      content: '';
      display: block;
      height: $form-custom-size;
      left: 0;
      line-height: $form-custom-size;
      overflow: hidden;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      transition: all $transition-duration $transition-timing;
      width: $form-custom-size;
    }

    &::after {
      background: _($colors, 'grey-500');
      border: 0;
      color: _($colors, 'brand-2');
      height: $form-custom-bullet-size;
      left: (($form-custom-size + 4px) - $form-custom-bullet-size) * 0.5;
      opacity: 0;
      padding: 0;
      width: $form-custom-bullet-size;
    }

    &:hover {

      &::after {
        opacity: .2;
      }
    }
  }

  input:checked + label {

    &::before {
      background-color: _($colors, 'white');
      border: 2px solid _($colors, 'grey-400');
    }

    &::after {
      background: _($colors, 'brand-1');
      opacity: 1;
    }
  }

  br {
    display: none;
  }
}

.checkbox {
  position: relative;

  .umbraco-forms-label {
    cursor: pointer;
    padding-left: 30px;
  }

  .umbraco-forms-field-wrapper {

    input {
      display: none;
    }

    span {
      display: block;
      height: 30px;
      padding-left: 30px;
      position: relative;
      z-index: -1;

      &:empty {
        height: 0;
      }

      &::after,
      &::before {
        border: 2px solid _($colors, 'grey-300');
        border-radius: 5px;
        content: '';
        display: block;
        height: $form-custom-size;
        left: 0;
        line-height: $form-custom-size;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: -18px;
        transform: translateY(-50%);
        transition: all $transition-duration $transition-timing;
        width: $form-custom-size;
      }

      &::after {
        background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22#{rgba(_($colors, 'brand-1'), .99)}%22%20d%3D%22M173.898%20439.404l-166.4-166.4c-9.997-9.997-9.997-26.206%200-36.204l36.203-36.204c9.997-9.998%2026.207-9.998%2036.204%200L192%20312.69%20432.095%2072.596c9.997-9.997%2026.207-9.997%2036.204%200l36.203%2036.204c9.997%209.997%209.997%2026.206%200%2036.204l-294.4%20294.401c-9.998%209.997-26.207%209.997-36.204-.001z%22%2F%3E%3C%2Fsvg%3E');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        color: _($colors, 'brand-2');
        height: $form-custom-check-size;
        left: ($form-custom-size + 4 - $form-custom-check-size) * 0.5;
        opacity: 0;
        width: $form-custom-check-size;
      }
    }

    input:checked + span {

      &::before {
        background-color: _($colors, 'white');
        border: 2px solid _($colors, 'grey-400');
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

.umbraco-forms-navigation {
  margin-bottom: 20px;
}

.field-validation-error {
  @include font-size(14);

  color: _($colors, 'status-error');
  display: block;
  width: 100%;
}

.btn {
  @extend .button;

  &.primary {
    @extend .button--brand-1;
  }
}
/*! purgecss end ignore */