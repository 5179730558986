/*! purgecss start ignore */
.video--embed {
  max-width: 960px;
  position: relative;

  .video-link {
    background: #000;
    display: block;
    position: absolute;

  

    .figure {
      display: block;
    }

    img {
      opacity: .75;
      position: relative;
      transition: opacity 150ms ease-in-out;
      width: 100%;
      z-index: 0;
    }

    // .icon {
    //   color: #fff;
    //   display: block;
    //   height: 20px;
    //   margin: 0 auto 10px;
    //   width: 20px;

    //   @include mq($bp-medium) {
    //     height: 32px;
    //     width: 32px;
    //   }

    //   svg {
    //     fill: currentColor;
    //   }
    // }

    &__info {
      align-items: center;
      bottom: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      left: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      z-index: 10;

      span {
        //   @extend .title--preset-5;

        //   .col--media & {
        //     @extend .title--preset-6;
        //   }
      }
    }

    // &:hover {

    //   img {
    //     opacity: .5;
    //   }
    // }
  }

  &:not(.active) {

    .video-player {
      display: none;
    }
  }

  // .video-info {
  //   @apply text-center leading-snug opacity-0 mt-1 mb-4;
  //   @apply transition-opacity duration-500 h-0;

  //   transition-delay: 2s;

  //   span {
  //     @apply inline-block;
  //     @apply transform -translate-y-8;
  //     @apply transition-all duration-500;

  //     transition-delay: 2s;
  //   }
  // }

  &.active {

    .video-link {
      display: none;
    }

    .video-info {
      @apply h-auto;
      @apply opacity-75;

      span {
        @apply transform translate-y-0;
      }
    }
  }
}
/*! purgecss end ignore */