.blockquote {
  box-sizing: border-box;
}

.blockquote-wrapper {
  max-width: 840px;
  position: relative;
  transform-style: preserve-3d;
  width: 80%;

  .figure {
    bottom: -20px;
    max-width: 100px;
    position: absolute;
    right: 20px;
    transform: perspective(1000px) translateZ(20px);

    @include mq($bp-small) {
      max-width: 270px;
      right: -90px;
      transform: perspective(1000px) translateZ(40px);
    }
  }

  &--avatar {

    .blockquote {
      @include mq($bp-small) {
        padding-right: 200px;
      }

      @include mq($bp-small, max) {

        &__author {
          padding-right: 100px;
        }
      }
    }
  }
}
