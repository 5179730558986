$form-item-height: 52px;
$form-item-min-width: 80px;
$form-item-max-width: 1000px;

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
select,
textarea {
  @include font-size(nth($font-sizes, 2));

  appearance: none;
  background-color: _($colors, 'white');
  background-position: 98% 50%;
  background-repeat: no-repeat;
  border: 2px solid $color-border;
  border-radius: 3px;
  // box-shadow: inset 5px 5px $gutter -6px rgba(_($colors, 'black'), .2);
  box-sizing: border-box;
  font-family: $font-default;
  height: $form-item-height;
  line-height: $form-item-height;
  margin: 0;
  max-width: $form-item-max-width;
  min-width: $form-item-min-width;
  outline: none;
  padding: 0 ($gutter * 2) 0 ($gutter * 0.5);
  transition: all $transition-duration $transition-timing;
  width: 100%;

  // @include mq($bp-large) {
  //   @include font-size(nth($font-sizes, 2));
  // }

  &:hover,
  &:focus {
    border: 2px solid _($colors, 'grey-400');
    // box-shadow: inset 5px 5px $gutter -6px rgba(_($colors, 'black'), 0), 0 2px ($gutter / 2) rgba(_($colors, 'black'), .2);
  }

  &::placeholder {
    color: _($colors, 'grey-500');
    font-style: normal;
  }

  &.interacted {

    &:required:valid {
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpolygon%20fill%3D%22#{rgba(_($colors, "status-ok"), .99)}%22%20points%3D%2222%2C6.1%2020.9%2C5%209.1%2C16.7%203.2%2C10.9%202.1%2C12%209.1%2C19%20%22%2F%3E%3C%2Fsvg%3E');
    }

    &:required:invalid {
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpolygon%20fill%3D%22#{rgba(_($colors, "status-error"), .99)}%22%20points%3D%2219%2C6.1%2017.9%2C5%2012%2C10.9%206.1%2C5%205%2C6.1%2010.9%2C12%205%2C17.9%206.1%2C19%2012%2C13.1%2017.9%2C19%2019%2C17.9%2013.1%2C12%22%20%2F%3E%3C%2Fsvg%3E');
      border-color: _($colors, 'status-error');
      // box-shadow: inset 5px 5px $gutter -6px rgba(_($colors, 'status-error'), .3);
    }
  }

  // &[type='number'],
  // &.number {
  //   text-align: right;
  // }
}

input[type='checkbox'] {

  .form--sent & {

    &:required:invalid + span {
      color: _($colors, 'status-error');
    }
  }
}

input[type='number'],
input[type='tel'] {
  padding-right: $gutter * 0.5;

  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

@import 'radio-checkbox',
  'radio-circle',
  'select',
  'textarea';
