@import 'opacity';

.h {

  &-border {
    border: 1px solid $color-border;
  }

  &-rounded {
    border-radius: $border-radius;
  }

  &-blend {
    mix-blend-mode: multiply;
  }
}
