.band--gdpr {

  &.hidden {
    display: none;

    + * {
      // padding-top: 100px !important; /* stylelint-disable-line declaration-no-important */
    }

    @include mq($bp-medium, max) {

      + .band--breadcrumb.hide-on-mobile {
        display: block !important; /* stylelint-disable-line declaration-no-important */
        height: 0;
        overflow: hidden;
      }
    }
  }

  &[style*='block'] + .band {
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}

.cookiebar {
  // border: 1px solid _($colors, 'brand-1');
  font-size: 90%;
  max-width: 1260px;
}
