/*! purgecss start ignore */
.row--projects {
  @include mq($bp-large) {
    flex-wrap: nowrap;
  }
}

.col--projectfilters {
  @include mq($bp-large) {
    flex: 0 0 260px;
    max-width: 260px;
  }
}

.col--projectgrid {
  @include mq($bp-large) {
    flex: 1 1 auto;
  }
}
/*! purgecss end ignore */