.figure {
  display: inline-block;
  max-width: 100%;
  text-align: center;

  &--video {
    width: 100%;
  }

  &--left {
    float: left;
    max-width: 50%;
  }

  &--right {
    float: right;
    max-width: 50%;
  }

  &--center {

    > img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  // &--rounded {
  //   // display: inline-block;

  //   img {
  //     border-radius: $border-radius;
  //     overflow: hidden;
  //     width: 100%;
  //   }
  // }

  img {
    width: 100%;
    // &.has--shadow {
    //   box-shadow: 0 60px 135px rgba(_($colors, 'black'), .15);
    // }
  }
}

img {
  display: block;
  max-width: 100%;

  &.has--shadow {
    box-shadow: 0 60px 135px rgba(_($colors, 'black'), .15);
  }
}

figcaption {
  color: _($colors, 'grey-700');
  font-size: 90%;
}

.overlay {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
  }

  // &::before {
  //   border: 50px solid _($colors, 'white');
  //   border-radius: 100%;
  //   box-shadow: 0 0 0 10px rgba(_($colors, 'white'), .5);
  //   height: 0;
  //   transition: all 200ms ease;
  //   width: 0;
  // }

  &::before {
    background-color: _($colors, 'white');
    border-radius: 50%;
    height: 75px;
    width: 75px;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 283.5 283.5'%3E%3Cpath fill='#{rgba(_($colors, "brand-1"), .99)}' d='M141.7,0C63.5,0,0,63.5,0,141.7s63.5,141.7,141.7,141.7S283.5,220,283.5,141.7S220,0,141.7,0z M124.6,232.6 l-30.3-30.3l30.3-30.3l-30.3-30.3l30.3-30.3L94.3,81.2l30.3-30.3l90.9,90.9L124.6,232.6z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }

  &:hover {

    &::before {
      box-shadow: 0 0 0 10px rgba(_($colors, 'brand-1'), .5);
    }
  }
}
