// .basicscroll {
//   transform: translateY(var(--translateY));
//   will-change: transform;
// }

.rellax {
  @include mq($bp-medium, max) {
    transform: translate3d(0, 0, 0) !important; /* stylelint-disable-line declaration-no-important */
  }
}
