@import 'family', 'helpers', 'lineheight', 'sizes', 'textalign', 'titles',
  'variations';

$font-default: $font-1;
$font-default-size: nth($font-sizes, 2);
$font-default-weight: 400;
$font-default-lineheight: nth($line-sizes, 3);

body {
  @include font-size($font-default-size);

  color: $color-text;
  font-family: $font-default;
  font-weight: $font-default-weight;
  line-height: $font-default-lineheight;
}

::selection {
  background: _($colors, 'grey-200');
  border: 1px solid _($colors, 'grey-200');
  color: _($colors, 'brand-2');
}
