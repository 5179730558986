.row--columns {

  .col--avatar {
    max-width: 30vw;

    @include mq(800, max) {
      margin-left: 20px;
      margin-top: 20px;
    }

    ~ .col {
      max-width: calc(100% - 30vw - 60px);
    }
  }

  .col--image {
    position: relative;

    @include mq(nth($grid-breakpoints, 2), max) {
      order: -1;
      text-align: center !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  @include mq(nth($grid-breakpoints, 2)) {

    .figure--half {
      max-width: 1000px;
      width: calc(50vw - 20px);
    }

    .col:nth-child(1) {

      .figure--half {
        float: right;
      }
    }
  }
}

.row--double-images {

  .col:not(.col--image) {

    > * {
      box-sizing: border-box;
      // background: green;

      @include mq($bp-medium) {
        max-width: calc(50vw - 40px);
        min-height: calc(50vw - 40px);
      }

      @include mq(1300) {
        max-width: 610px;
        min-height: 610px;
      }
    }
  }

  img {
    width: 100%;

    @include mq($bp-medium, max) {
      box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .col--image {
    @include mq(400, max) {

      .figure {
        display: block;
        margin: 0;
        width: 100%;

        // img {
        //   width: 100%;
        // }
      }
    }

    @include mq(400) {
      align-items: flex-end;
      display: flex;
      flex-wrap: nowrap;

      .figure {
        flex: 1 1 auto;
        max-width: 50%;
      }
    }

    @include mq($bp-medium) {
      position: static;

      .figure:first-child {
        margin-top: 60px;
        max-width: 70%;
      }

      .figure + .figure {
        left: 50%;
        margin-left: 50px;
        max-width: 30%;
        position: absolute;
        top: -20px;
        transform: translateX(-100%);
        z-index: -1;
      }
    }
  }

  @include mq($bp-medium) {

    .col + .col--image {
      justify-content: flex-end;

      .figure + .figure {
        transform: translateX(-100px);
      }
    }
  }

  // @include mq($bp-large) {

  //   .figure {
  //     max-width: 70%;
  //   }

  //   .figure + .figure {
  //     left: auto !important; /* stylelint-disable-line declaration-no-important */
  //     max-width: 70%;
  //     right: 0;
  //   }

  //   .col + .col--image {

  //     .figure {
  //       text-align: right;
  //     }

  //     .figure + .figure {
  //       // right: auto !important; /* stylelint-disable-line declaration-no-important */
  //       // left: 0 !important; /* stylelint-disable-line declaration-no-important */
  //       transform: translateX(-130%);
  //     }
  //   }
  // }
}

// Kolommen: TEXT TEXT

// .row--texttext {

// }

.col--intro {
  @include mq(nth($grid-breakpoints, 2), max) {
    order: -1;
  }
}

.col__text-intro {
  position: relative;
  z-index: 1;

  @include mq($bp-medium) {
    min-height: 33vw;
  }

  @include mq(1300) {
    min-height: 420px;
  }

}

.col__text-desc {
  position: relative;
  z-index: 0;

  @include mq($bp-medium) {

    &::before {
      background: _($colors, 'brand-10');
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: -60px;
      top: 0;
      width: 60px;
    }

    .col--intro + .col > &:before {
      left: -60px;
      right: auto;
    }
  }
}
