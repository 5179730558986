$margin-types: (
  'top': 't',
  'bottom': 'b',
  'left': 'l',
  'right': 'r',
  'center': 'c',
  'horizontal': 'h',
  'vertical': 'v'
);

$margin-sizes: (
  '0': 0,
  '_4': $gutter * 0.25,
  '_2': $gutter * 0.5,
  '': $gutter,
  '2': $gutter * 2,
  '3': $gutter * 3,
  '4': $gutter * 4,
  '5': $gutter * 5,
  '-': -$gutter,
  '-2': -$gutter * 2,
  '-3': -$gutter * 3,
  '-4': -$gutter * 4,
  '-5': -$gutter * 5,
);

@each $type, $short in $margin-types {

  .m#{$short} {
    @if $type == 'center' {
      margin-left: auto;
      margin-right: auto;
    }

    @else {
      @if $type != 'horizontal' or $type != 'vertical' {
        margin-#{$type}: map-get($margin-sizes, '1');
      }

      @each $size, $value in $margin-sizes {

        &#{$size} {
          @if $type == 'horizontal' {
            margin-left: $value;
            margin-right: $value;
          }

          @else if $type == 'vertical' {
            margin-bottom: $value;
            margin-top: $value;
          }

          @else {
            margin-#{$type}: $value;
          }
        }
      }
    }
  }

  @each $bptype, $bpshort in $breakpoints-types {
    @include mq($bptype) {

      .m#{$short} {
        @if $type == 'center' {

          &#{$bpshort} {
            margin-left: auto;
            margin-right: auto;
          }
        }

        @else {
          @if $type != 'horizontal' or $type != 'vertical' {
            margin-#{$type}: map-get($margin-sizes, '1');
          }

          @each $size, $value in $margin-sizes {

            &#{$size}#{$bpshort} {
              @if $type == 'horizontal' {
                margin-left: $value;
                margin-right: $value;
              }

              @else if $type == 'vertical' {
                margin-bottom: $value;
                margin-top: $value;
              }

              @else {
                margin-#{$type}: $value;
              }
            }
          }
        }
      }
    }
  }
}

.no-next-padding {

  + * {
    padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}
