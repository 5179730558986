.follow-list {
  flex-wrap: wrap;

  .col--follow {
    flex: 0 0 auto;
    width: 100%;

    @include mq($bp-xsmall) {
      flex: 1 1 auto;
      width: 50%;
    }

    @include mq($bp-small) {
      flex: 1 1 auto;
      width: auto;
      max-width: 33%;
    }

    @include mq($bp-large) {
      flex: 1 1 auto;
      width: auto;
      max-width: 25%;
    }
  }
}

.follow-link {
  display: block;
  padding: 10px;
  text-align: center;
}
