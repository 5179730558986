.grid {
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  grid-template-columns: repeat(1, calc(100%));
  max-width: 1300px;
  width: 100%;

  @include mq($bp-small) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, calc(50% - 20px / 2));
  }

  @include mq($bp-medium) {
    grid-gap: 40px;
    grid-template-columns: repeat(2, calc(50% - 40px / 2));
  }

  @include mq($bp-xlarge) {
    grid-template-columns: repeat(3, calc(33.33% - (80px / 3)));
  }

  .col--projectgrid & {
    @include mq($bp-large) {
      grid-gap: 40px;
      grid-template-columns: repeat(2, calc(50% - 40px / 2));
    }

    @include mq($bp-xlarge) {
      grid-template-columns: repeat(3, calc(33.33% - (80px / 3)));
    }
  }

  .no-cssgrid & {
    display: flex;
    flex-wrap: wrap;
    left: -10px;
    position: relative;
    width: calc(100% + #{$gutter});

    > * {
      box-sizing: border-box;
      flex: 0 0 100%;
      margin-bottom: $gutter;
      max-width: 100%;
      padding: 0 $gutter * 0.5;

      @include mq($bp-small) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include mq($bp-large) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
  }
}
