/*! purgecss start ignore */

@keyframes slide-up {

  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {

  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(150%);
  }
}

.contactcard {
  background: _($colors, 'brand-2');
  border: 2px solid #fff;
  box-shadow: 0 0 $gutter 0 rgba(_($colors, 'black'), .2);
  color: #fff;
  display: none;
  font-size: 16px;
  line-height: 1.1;
  max-width: 380px;
  min-width: 280px;

  @include mq($bp-medium) {
    display: block;
  }

  // &.show {
  //   display: block;
  // }

  body.body--show-contact & {
    display: block;
  }

  body.body--hide-contact & {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .site & {
    bottom: 0;
    margin: 10px;
    position: fixed;
    right: 0;
    transform: translateY(150%);
    z-index: 999;

    &.show {
      animation: slide-up 1s cubic-bezier(.68, -.55, .265, 1.55) forwards;
      transform: translateY(150%);
    }

    &.hide {
      animation: slide-down 1s cubic-bezier(.68, -.55, .265, 1.55) forwards;
    }
  }

  &__body {
    padding: 10px 15px 0;
    position: relative;

    span {
      display: block;
      padding-bottom: 5px;
    }
  }

  &__close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{rgba(_($colors, 'white'), .99)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;

    &:hover {
      opacity: .8;
    }
  }

  &__image {
    display: inline;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 60px;
    min-width: 40px;

    @include mq($bp-medium) {
      max-width: 80px;
      min-width: 60px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__title {
    font-size: 20px;
  }

  ul {
    margin-bottom: 10px;
  }

  &__info {
    flex: 1 1 auto;
    // flex-basis: 200px;
  }

  &__link {
    border: 0;
    color: #fff !important; /* stylelint-disable-line declaration-no-important */
    display: block;
    max-width: calc(100% - 30px);
    overflow: hidden;
    padding-bottom: 5px;
    position: relative;
    text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover {
      text-decoration: underline !important; /* stylelint-disable-line declaration-no-important */
    }

    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 18px;
      left: 0;
      position: absolute;
      top: 0;
      width: 18px;
    }

    &--phone {
      padding-left: 25px;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{rgba(_($colors, 'white'), .99)}' d='M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z'/%3E%3C/svg%3E");
      }
    }

    &--mail {
      padding-left: 25px;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{rgba(_($colors, 'white'), .99)}' d='M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z'/%3E%3C/svg%3E");
      }
    }
  }
}

.js-toggle-contact {
  display: none;

  .site & {
    border: 2px solid #fff;
    bottom: 0;
    box-shadow: 0 0 $gutter 0 rgba(_($colors, 'black'), .2);
    cursor: pointer;
    display: block;
    margin: 10px;
    max-width: 60px;
    padding: 0;
    position: fixed;
    right: 0;
    z-index: 999;

    body.body--show-contact & {
      display: none;
    }

    img {
      width: 100%;
    }

    @include mq($bp-medium) {
      display: none;
    }
  }
}
/*! purgecss end ignore */