$padding-types: (
  'all': 'a',
  'top': 't',
  'bottom': 'b',
  'left': 'l',
  'right': 'r',
  'horizontal': 'h',
  'vertical': 'v'
);

$padding-sizes: (
  '0': 0,
  '_4': $gutter * 0.25,
  '_2': $gutter * 0.5,
  '': $gutter,
  '2': $gutter * 2,
  '3': $gutter * 3,
  '4': $gutter * 4,
  '5': $gutter * 5
);

@each $type, $short in $padding-types {

  .p#{$short} {
    @if $type == 'all' {
      padding: map-get($padding-sizes, '1');
    }

    @each $size, $value in $padding-sizes {

      &#{$size} {
        @if $type == 'all' {
          padding: $value;
        }

        @else {
          @if $type == 'horizontal' {
            padding-left: $value;
            padding-right: $value;
          }

          @else if $type == 'vertical' {
            padding-bottom: $value;
            padding-top: $value;
          }

          @else {
            padding-#{$type}: $value;
          }
        }
      }
    }
  }

  @each $bptype, $bpshort in $breakpoints-types {
    @include mq($bptype) {

      .p#{$short} {
        @if $type == 'all' {
          padding: map-get($padding-sizes, '1');
        }

        @each $size, $value in $padding-sizes {

          &#{$size}#{$bpshort} {
            @if $type == 'all' {
              padding: $value;
            }

            @else {
              @if $type == 'horizontal' {
                padding-left: $value;
                padding-right: $value;
              }

              @else if $type == 'vertical' {
                padding-bottom: $value;
                padding-top: $value;
              }

              @else {
                padding-#{$type}: $value;
              }
            }
          }
        }
      }
    }
  }
}
