/*! purgecss start ignore */

.project-header {

  &__text {
    @include mq($bp-large) {
      width: 100%;
    }
  }

  .col {
    z-index: 2;

    &--image {
      z-index: 1;

      @include mq($bp-large, max) {
        margin-bottom: 10px;
        order: -1;
      }
    }
  }

  &__image {
    @include mq($bp-large) {
      margin-left: -5%;
      // max-width: 120%;
      // width: 120%;
    }

    img {
      width: 100%;
    }
  }
}

/*! purgecss end ignore */