@use "sass:math";

table {
  width: 100%;

  thead {
    background-color: _($colors, 'brand-1');
    color: _($colors, 'white');
    font-weight: bold;
    text-align: left;

    th {
      padding: $gutter * 0.25 math.div($gutter, 1.5);
    }
  }

  tbody {

    tr {
      border-bottom: 1px solid rgba(_($colors, 'grey-500'), .5);
    }

    td {
      padding: $gutter * 0.5 math.div($gutter, 1.5);
    }
  }

  @include mq($bp-small, max) {
    border: 1px solid rgba(_($colors, 'grey-500'), .5);
    display: block;

    thead {
      display: none;
    }

    tbody,
    tr,
    td {
      display: block;
    }

    tbody {
      padding: 0 $gutter * 0.5;

      tr {
        margin: ($gutter * 0.5) 0;

        &:last-child {
          border-bottom: 0;
        }
      }

      td {
        margin-bottom: $gutter * 0.25;
        padding: 0;

        &::before {
          color: _($colors, 'grey-500');
          content: attr(data-name);
          display: block;
          font-size: 85%;
          line-height: 1;
        }
      }
    }
  }
}
