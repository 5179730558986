$grid-breakpoints: 1024, 768, 600;
$responsive-grid: (
  l: nth($grid-breakpoints, 1),
  m: nth($grid-breakpoints, 2),
  s: nth($grid-breakpoints, 3)
);

.row {
  box-sizing: border-box;
  display: flex;
  margin-bottom: $margin-bottom;
  position: relative;
  width: 100%;

  @include mq($bp-xsmall, max) {
    flex-wrap: wrap;
  }

  .row {
    margin-bottom: 0;
  }

  &--spacing {
    left: -$gutter;
    width: calc(100% + #{$gutter * 2}) !important; /* stylelint-disable-line declaration-no-important */

    .row {
      left: -$gutter;
      width: calc(100% + #{$gutter * 2});

      &--nospacing {
        left: 0;
        width: 100%;
      }
    }
  }
}

.col {
  box-sizing: border-box;
  flex: 1 1 auto;
  max-width: 100%;

  .row--spacing & {
    padding: 0 $gutter;
  }

  .row--nospacing & {
    padding: 0;
  }

  // @include mq($bp-xsmall, max) {
  //   flex: 0 0 100% !important; /* stylelint-disable-line declaration-no-important */
  //   max-width: 100% !important; /* stylelint-disable-line declaration-no-important */
  // }

  &.no-stretch {
    flex: auto !important; /* stylelint-disable-line declaration-no-important */
    flex-grow: 0 !important; /* stylelint-disable-line declaration-no-important */
    flex-shrink: 0 !important; /* stylelint-disable-line declaration-no-important */
    max-width: inherit;

    @include mq($bp-small) {

      &-s {
        flex: auto !important; /* stylelint-disable-line declaration-no-important */
        flex-grow: 0 !important; /* stylelint-disable-line declaration-no-important */
        flex-shrink: 0 !important; /* stylelint-disable-line declaration-no-important */
        max-width: inherit;
      }
    }

    @include mq($bp-medium) {

      &-m {
        flex: auto !important; /* stylelint-disable-line declaration-no-important */
        flex-grow: 0 !important; /* stylelint-disable-line declaration-no-important */
        flex-shrink: 0 !important; /* stylelint-disable-line declaration-no-important */
        max-width: inherit;
      }
    }
  }

  @include mq($bp-small) {

    &--100 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &--90 {
      flex: 0 0 90%;
      max-width: 90%;
    }

    &--80 {
      flex: 0 0 80%;
      max-width: 80%;
    }

    &--75 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &--66 {
      flex: 0 0 66.66%;
      max-width: 66.66%;
    }

    &--50 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &--33 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    &--25 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &--0 {
      flex: 0 0 auto;
    }
  }

  @each $grid, $gridbp in $responsive-grid {
    @include mq($gridbp, max) {

      &--90-#{$grid} {
        flex: 0 0 90%;
        max-width: 90%;
      }

      &--80-#{$grid} {
        flex: 0 0 80%;
        max-width: 80%;
      }

      &--100-#{$grid} {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &--75-#{$grid} {
        flex: 0 0 75%;
        max-width: 75%;
      }

      &--66-#{$grid} {
        flex: 0 0 66%;
        max-width: 66%;
      }

      &--50-#{$grid} {
        flex: 0 0 50%;
        max-width: 50%;
      }

      &--33-#{$grid} {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      &--25-#{$grid} {
        flex: 0 0 25%;
        max-width: 25%;
      }

      &--0-#{$grid} {
        flex: 0 0 auto;
      }
    }
  }
}
