// Colors

$colors: (
  'black': #000000,
  'brand-1': #002d62,
  'brand-2': #f15922,
  'brand-3': #ed1a3b,
  'brand-4': #69a38a,
  'brand-5': #831d81,
  'brand-6': #924633,
  'brand-7': #9f6f2f,
  'brand-8': #55534d,
  'brand-9': #de9801,
  'brand-10': #f3f1ec,
  'duurzaam': #3dad04,
  'grey-100': #f6f6f6,
  'grey-200': #ededed,
  'grey-300': #ddd,
  'grey-400': #aaa,
  'grey-500': #999,
  'grey-600': #666,
  'grey-700': #333,
  'grey-800': #1c1c1b,
  'status-error': #e41b13,
  'status-info': #fff2af,
  'status-ok': #3dad04,
  'white': #ffffff
);

@if $theme == 'buurblok' {
  $colors: (
    'black': #000000,
    'brand-1': #1f395c,
    'brand-2': #ed9e35,
    'brand-3': #59b259,
    'brand-4': #59b259,
    'brand-5': #831d81,
    'brand-6': #924633,
    'brand-7': #9f6f2f,
    'brand-8': #55534d,
    'brand-9': #ed9e35,
    'brand-10': #f3f1ec,
    'duurzaam': #59b259,
    'grey-100': #f6f6f6,
    'grey-200': #ededed,
    'grey-300': #ddd,
    'grey-400': #aaa,
    'grey-500': #999,
    'grey-600': #666,
    'grey-700': #333,
    'grey-800': #1c1c1b,
    'status-error': #e41b13,
    'status-info': #fff2af,
    'status-ok': #59b259,
    'white': #ffffff
  );
}

$hover-colors: (
  'brand-1': darken(_($colors, 'brand-1'), 5),
  'brand-2': darken(_($colors, 'brand-2'), 5),
  'brand-3': darken(_($colors, 'brand-3'), 5),
  'brand-4': darken(_($colors, 'brand-4'), 5),
  'brand-5': darken(_($colors, 'brand-5'), 5),
  'brand-6': darken(_($colors, 'brand-6'), 5),
  'brand-7': darken(_($colors, 'brand-7'), 5),
  'brand-8': darken(_($colors, 'brand-8'), 5),
  'brand-9': darken(_($colors, 'brand-9'), 5),
  'brand-10': darken(_($colors, 'brand-10'), 5)
);

// Global colors
$color-body:    _($colors, 'white');
$color-text:    _($colors, 'grey-600');
$color-border:  _($colors, 'grey-300');
