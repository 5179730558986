.band {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
  position: relative;
  width: 100%;
  z-index: 1;

  @if $theme == 'buurblok' {

    &.bc-brand-1 {
      background-color: _($colors, 'brand-3');
    }
  }

  &__content {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    position: relative;
    z-index: 2;

    &--full {
      max-width: 100%;
    }
  }

  &--fixed {
    position: fixed;
    z-index: 2;
  }

  &__background {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover !important; /* stylelint-disable-line declaration-no-important */
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &--navigation {
    z-index: 2;
  }
}
