.link,
p a:not(.button):not([class*='c-']),
li a:not(.button):not([class*='c-']),
td a:not(.button):not([class*='c-']) {
  color: _($colors, 'grey-800');
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color $transition-duration $transition-timing;

  &::after {
    background-color: _($colors, 'grey-800');
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    transition: all $transition-duration $transition-timing;
    width: 100%;
  }

  &:hover {

    &::after {
      height: 2px;
    }
  }
}

.link {
  text-decoration: none;

  &-style2 {
    color: _($colors, 'grey-800');
    text-decoration: none !important; /* stylelint-disable-line declaration-no-important */

    &::after {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover {
      opacity: .8;
    }
  }

  &-white {
    color: _($colors, 'white') !important; /* stylelint-disable-line declaration-no-important */
    text-decoration: none;

    &::after {
      background-color: rgba(_($colors, 'white'), .5) !important; /* stylelint-disable-line declaration-no-important */
    }

    &:hover {

      &::after {
        background-color: _($colors, 'white') !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  @for $i from 1 through 10 {

    &-brand-#{$i} {
      color: _($colors, 'brand-#{$i}') !important; /* stylelint-disable-line declaration-no-important */
      text-decoration: none;

      &::after {
        background-color: rgba(_($colors, 'brand-#{$i}'), .5) !important; /* stylelint-disable-line declaration-no-important */
      }

      &:hover {
        color: _($hover-colors, 'brand-#{$i}') !important; /* stylelint-disable-line declaration-no-important */

        &::after {
          background-color: _($hover-colors, 'brand-#{$i}') !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      &.link-style2 {

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.c-white a:not([class*='c-']) {
  color: _($colors, 'white') !important; /* stylelint-disable-line declaration-no-important */

  &::after {
    background-color: _($colors, 'white') !important; /* stylelint-disable-line declaration-no-important */
  }
}
