// Text align

.ta {

  &-l {
    text-align: left;
  }

  &-c {
    text-align: center;
  }

  &-r {
    text-align: right;
  }
}
