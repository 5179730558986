/*! purgecss start ignore */

@if $theme == 'buurblok' {

  // <!-- START: QUICK FIX FOR REMOVING NAVIGATION ON SUBSITE -->

  // .col--navigation .col:not(.col--logo),

  .navigation-social .icon-size2 {
    height: 32px;
    width: 32px;

    & > svg {
      max-height: 32px;
    }
  }

  .col--navigation .search-form,
  .col--footermenu .hide-on-mobile {
    display: none;
  }

  .site .band--footer .col--social.hide-on-mobile.hide-on-tablet {
    display: block !important; /* stylelint-disable-line declaration-no-important */
    width: 100%;

    .flx {
      justify-content: center;
      width: 100%;
    }

    @include mq($bp-large) {
      width: auto;
    }
  }

  .band--footer .band__content {
    max-width: 1300px !important; /* stylelint-disable-line declaration-no-important */
  }

  // .col--footermenu + .col--social {
  //   padding-top: 10px;
  // }

  // .band--footer .flx {
  //   justify-content: center !important; /* stylelint-disable-line declaration-no-important */
  // }

  // .band--footer .col--footermenu {
  //   flex: 0 0 auto;

  //   .pa {
  //     padding-bottom: 0;
  //   }
  // }

  // <!-- END: QUICK FIX FOR REMOVING NAVIGATION ON SUBSITE -->
}

.col--logo {
  flex: 0 0 auto;
  max-width: 70vw;
  width: 240px;

  @if $theme == 'buurblok' {
    width: 184px;
  }

  @else {
    width: 240px;
  }

  @include mq($bp-mobilemenu) {
    flex: 0 0 240px;
  }

  @include mq($bp-xlarge) {
    position: relative;
    top: 5px;

    @if $theme != 'buurblok' {
      margin-right: 10px;
    }
  }
}

.icon--logo-white,
.icon--logo,
.icon--logo-buurblok-white,
.icon--logo-buurblok {
  padding-top: 10px;

  @if $theme == 'buurblok' {
    min-height: 64px;

    @include mq($bp-medium) {
      min-height: 80px;

      .band--footer & {
        min-height: 64px;
      }
    }

    @include mq($bp-large) {

      .band--footer & {
        margin-top: -10px;
      }
    }
  }

  @else {
    min-height: 52px;
  }

  svg {
    width: 100%;

    @if $theme == 'buurblok' {
      min-height: 64px;

      @include mq($bp-medium) {
        min-height: 80px;
      }
    }

    @else {
      max-height: 52px;
    }
  }
}

.band--navigation {
  @include mq($bp-mobilemenu, max) {

    .body--showmenu & {
      background: _($colors, 'white');
      bottom: 0;
      height: 100%;
      left: 0;
      margin: 0;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll; /* has to be scroll, not auto */
      position: fixed;
      right: 0;
      top: 0;
    }
  }

  @include mq($bp-mobilemenu) {
    margin-bottom: 10px !important; /* stylelint-disable-line declaration-no-important */
    text-align: left;
  }

  .col {
    // border: 1px solid pink;
    box-sizing: border-box;

    // &--search {
    //   position: relative;
    //   z-index: 2;
    //   // border: 1px solid pink;

    //   @include mq($bp-mobilemenu, max) {
    //     width: 100%;
    //     // margin-right: 0;
    //     // margin-bottom: 20px;
    //     // margin-top: 10px;
    //   }

    //   @include mq($bp-large) {
    //     max-width: 400px;
    //   }
    // }

    &--mainmenu {
      position: relative;
      z-index: 4;

      @if $theme == 'buurblok' {
        @include mq(960, max) {
          flex: 0 0 100%;
          margin-top: 10px;
          max-width: 100%;
          order: 99;
        }
      }

      @else {
        @include mq(3200, max) {
          flex: 0 0 100%;
          margin-top: 10px;
          max-width: 100%;
          order: 99;

          @include mq(1280) {
            order: inherit;
            flex: 1 1 auto;
            // border: 2px solid red;
          }



          // OPTIE 1
          // geen order
          // flex: 1 1 auto;
        }

      }

      @include mq($bp-mobilemenu, max) {
        display: none;

        .body--showmenu & {
          display: block;
          padding-bottom: 40px;
        }
      }

      // .main-menu__link {
      //   font-weight: bold;
      //   text-transform: uppercase;
      // }

      // .main-menu__item {

      //   &.selected {

      //     .main-menu__link {
      //       color: _($colors, 'brand-2');
      //     }
      //   }
      // }
    }

    &--navigation {
      @include mq($bp-mobilemenu, max) {

        > .row {
          align-items: center !important; /* stylelint-disable-line declaration-no-important */
        }

        .body--showmenu & {
          display: flex;
          width: 100%;
        }
      }

      // OPTIE 1

      // @include mq(1280) {
      //   > .row {
      //     flex-wrap: nowrap;
      //   }
      // }

      @include mq($bp-mobilemenu) {
        display: block;
        position: relative;
        top: -8px;
      }

      @include mq(1280) {
        > .flx-w {
          flex-wrap: nowrap;
        }
      }
    }

    &--toggle-navigation {
      display: block;

      .icon--closemenu {
        display: none;

        .body--showmenu & {
          display: inline-block;
        }
      }

      .body--showmenu & {

        .icon--menu {
          display: none;
        }
      }

      @include mq($bp-mobilemenu) {
        display: none;
      }
    }

    &--social {
      @include mq($bp-mobilemenu, max) {
        display: none;
        flex: 0 0 100% !important; /* stylelint-disable-line declaration-no-important */
        margin: 20px 0;

        .body--showmenu & {
          display: block;
        }
      }

      @include mq($bp-mobilemenu) {
        order: 99;
      }
    }
  }
}

.nav {
  flex-wrap: wrap;

  @include mq($bp-medium) {
    flex-wrap: nowrap;
  }
}

.navigation-social {
  @include mq($bp-mobilemenu, max) {
    display: none;
  }
}

.navigation-branche {
  position: relative;

  @include mq($bp-mobilemenu, max) {
    display: none;

    .body--showmenu & {
      display: block;
      padding-bottom: 40px;
    }
  }

  @include mq($bp-mobilemenu) {
    margin-top: 5px;
    padding-top: 10px;

    &::before {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100vw;
      height: 1px;
      display: block;
      content: '';
      background: _($colors, 'grey-200');
      transform: translateX(-50%);
    }

    &::after {
      position: absolute;
      left: 50%;
      bottom: -10px;
      width: 100vw;
      height: 1px;
      display: block;
      content: '';
      background: _($colors, 'grey-200');
      transform: translateX(-50%);
    }

    .main-menu__link {
      @include font-size(18);

      font-weight: normal;
      text-transform: initial;
    }
  }
}


/*! purgecss end ignore */