/*! purgecss start ignore */

.fslightbox-toolbar {
  background: _($colors, 'brand-1');
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.fslightbox-toolbar-button {
  cursor: pointer;
  height: 100%;
  width: 45px;
}

.lds-ring {
  display: block;
  height: 67px;
  margin: auto;
  position: relative;
  width: 67px;
}

.lds-ring div {
  animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border: 5px solid _($colors, 'grey-300');
  border-color: _($colors, 'grey-300') transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 54px;
  margin: 6px;
  position: absolute;
  width: 54px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fslightbox-source-holder {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fslightbox-single-source {
  backface-visibility: hidden;
  display: block;
  margin: auto;
  transform: translateZ(0);
}

.fslightbox-transform-transition {
  transition: transform .3s;
}

.fslightbox-invalid-file-wrapper {
  align-items: center;
  color: _($colors, 'grey-200');
  display: flex;
  font-size: 22px;
  justify-content: center;
  margin: auto;
}

.fslightbox-fade-in {
  opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
}

.fslightbox-fade-in-animation {
  animation: fade-in .25s linear;
}

@keyframes fade-in {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fslightbox-fade-out-animation {
  animation: fslightbox-fade-out-anim .25s linear;
}

@keyframes fslightbox-fade-out-anim {

  0% {
    opacity: .2;
  }

  100% {
    opacity: 0;
  }
}

.fslightbox-container {
  background: _($colors, 'white');
  bottom: 0;
  height: 100%;
  position: fixed;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: 9999999;
}

.fslightbox-open {
  height: 100%;
  overflow: hidden;
}

.fslightbox-scrollbarfix {
  padding-right: 17px;
}

.fslightbox-nav {
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.fslightbox-slide-number-container {
  @include font-size(14);

  align-items: center;
  color: _($colors, 'text');
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 46px;
  z-index: 1;
}

.fslightbox-slide-number-container .fslightbox-slide-slide-number {
  padding: 0 2px;
}

.fslightbox-slide-number-container .fslightbox-slash {
  font-size: 90%;
  padding-top: .15rem;
}

.fslightbox-svg-icon path,
.fslightbox-svg-icon polygon,
.fslightbox-svg-icon rect {
  fill: _($colors, 'grey-300');
}

.fslightbox-svg-icon circle {
  stroke: _($colors, 'brand-1');
  stroke-width: 1;
}

.fslightbox-slide-btn-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 30px 30px 30px 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.fslightbox-slide-btn-container:hover .fslightbox-svg-icon path,
.fslightbox-slide-btn-container:hover .fslightbox-svg-icon polygon,
.fslightbox-slide-btn-container:hover .fslightbox-svg-icon rect {
  fill: _($colors, 'grey-100');
}

.fslightbox-slide-btn {
  @include font-size(26);

  background: _($colors, 'brand-1');
  padding: 7px;
}

@include mq(476) {

  .fslightbox-slide-btn {
    padding: 8px;
  }
}

.fslightbox-slide-btn-left-container {
  left: 0;
}

@include mq(476, max) {

  .fslightbox-slide-btn-left-container {
    padding-left: 3px;
  }
}

.fslightbox-slide-btn-right-container {
  padding-left: 30px;
  padding-right: 3px;
  right: 0;
}

@include mq(476) {

  .fslightbox-slide-btn-right-container {
    padding-right: 6px;
  }
}

.button-style {
  align-items: center;
  display: flex;
  justify-content: center;
}

.fslightbox-holder-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fslightbox-media-holder {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fslightbox-invisible-hover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.fslightbox-cursor-grabbing {
  cursor: grabbing;
}

.button-style:hover .fslightbox-svg-icon path,
.button-style:hover .fslightbox-svg-icon polygon,
.button-style:hover .fslightbox-svg-icon rect {
  fill: _($colors, 'white');
}

.fslightbox-fade-in-window {
  animation: fade-in .1s linear;
}

.fslightbox-container-fadeout {
  opacity: 0;
  transition: opacity .25s ease;
}
/*! purgecss end ignore */