@mixin font-size($font-default-size: 16) {
  // scss-lint:disable DuplicateProperty
  font-size: #{$font-default-size}px;
  font-size: #{$font-default-size * 0.1}rem;
  // scss-lint:enable DuplicateProperty
}

@mixin default-font() {
  font-family: $font-default;
  font-weight: $font-default-weight;
  line-height: $font-default-lineheight;
}

@mixin title-font($font-size: 0) {
  @if $font-size != 0 {
    @include font-size($font-size);
  }

  font-family: $font-heading;
  font-weight: $font-heading-weight;
  line-height: $font-heading-lineheight;
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
