/*! purgecss start ignore */

@import 'bands', 'flex', 'grid', 'hide', 'margins', 'paddings';

.body--showmenu {
  overflow: hidden;
}

.site {
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
}

.bands {
  // Used to be able to change the order of bands on mobile
  @include mq($bp-medium, max) {
    display: flex;
    flex-direction: column;
  }
}

.block {
  display: block;
}

.contain {
  max-width: 100%;
  overflow: hidden;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw-1000 {
  max-width: 1000px;
}

.p-rel {
  position: relative;
}

.p-abs {
  position: absolute;
}

$indexes: (
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '10': 10,
  '20': 20,
  '50': 50
);

@each $index, $value in $indexes {

  .z-#{$index} {
    z-index: $value;
  }
}

/*! purgecss end ignore */