.toggle {
  $self: &;

  input {
    display: none;

    &:checked {

      ~ #{$self}__title {

        #{$self}__icon {
          transform: rotateX(180deg);
        }
      }

      ~ #{$self}__content {
        display: block;
      }
    }
  }

  &__title {
    cursor: pointer;
  }

  &__icon {
    cursor: pointer;
    display: inline-block;
    // margin-left: .1em;
    transition: transform 200ms $transition-timing;
  }

  &__content {
    display: none;
  }

  @include mq($bp-medium) {

    &--mobile {

      #{$self}__title {
        cursor: default;
      }

      #{$self}__icon {
        display: none;
      }

      #{$self}__content {
        display: block;
      }
    }
  }
}
