/*! purgecss start ignore */
.video-player {
  height: 0;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 0 56.25%; // 16:9 ratio
  position: relative;
  width: 100%;

  &.has--shadow {
    box-shadow: 0 60px 135px rgba(_($colors, 'black'), .15);
  }

  iframe,
  object,
  embed,
  video {
    height: 100% !important; /* stylelint-disable-line declaration-no-important */
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }

  video {
    // transform: scale(1.15);
  }
}

.video-controls {
  // border: 1px solid pink;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 2;

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0 5px;
    transition: opacity 200ms;

    &:hover {
      opacity: .75;
    }
  }

  .toggle-sound {

    .icon--volume {
      display: inline-block;
    }

    .icon--volume-mute {
      display: none;
    }

    &.muted {

      .icon--volume {
        display: none;
      }

      .icon--volume-mute {
        display: inline-block;
      }
    }
  }

  .toggle-play {

    .icon--play-circle {
      display: none;
    }

    .icon--pause-circle {
      display: inline-block;
    }

    &.paused {

      .icon--play-circle {
        display: inline-block;
      }

      .icon--pause-circle {
        display: none;
      }
    }
  }

  .progress {
    display: flex;
    height: 4px;
    margin-top: 5px;
    position: relative;
  }

  .filled-progress {
    background: _($colors, 'brand-2');
    flex: 0;
    flex-basis: 0;
    width: 50%;
  }
}
/*! purgecss end ignore */