.flx {
  display: flex;

  &::after {
    content: '';
    font-size: 0;
    min-height: inherit;
  }

  &-column {
    flex-direction: column;
  }

  // ALIGN ITEMS

  &-ais {
    align-items: stretch;
  }

  &-aifs {
    align-items: flex-start;
  }

  &-aife {
    align-items: flex-end;
  }

  &-aic {
    align-items: center;
  }

  &-aib {
    align-items: baseline;
  }

  // ALIGN CONTENT

  &-acs {
    align-content: stretch;
  }

  &-acfs {
    align-content: flex-start;
  }

  &-acfe {
    align-content: flex-end;
  }

  &-acc {
    align-content: center;
  }

  &-acsb {
    align-content: space-between;
  }

  &-acsa {
    align-content: space-around;
  }

  // JUSTIFY CONTENT

  &-jcfs {
    justify-content: flex-start;
  }

  &-jcfe {
    justify-content: flex-end;
  }

  &-jcc {
    justify-content: center;
  }

  &-jcsb {
    justify-content: space-between;
  }

  &-jcsa {
    justify-content: space-around;
  }

  // WRAP

  &-w {
    flex-wrap: wrap;
  }

  &-wr {
    flex-wrap: wrap-reverse;
  }

  &-wn {
    flex-wrap: nowrap;
  }
}
