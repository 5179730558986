$form-textarea-max-height: 600px;
$form-textarea-min-height: 200px;

textarea {
  background-position: 98% 10px;
  height: auto;
  line-height: $font-default-lineheight;
  max-height: $form-textarea-max-height;
  min-height: $form-textarea-min-height;
  padding-top: $gutter * 0.5;
  resize: vertical;
}
