/*! purgecss start ignore */
.band--timeline {

  .tns-controls {
    @include mq($bp-medium) {
      left: 50%;
      margin-left: 70px;
      position: absolute;
      top: $gutter;
      z-index: 2;
    }
  }

  .slider {
    margin-bottom: 0;
    padding-bottom: 0;

    &__item {
      position: relative;

      @include mq($bp-large) {

        &::before {
          background: _($colors, 'grey-300');
          content: '';
          display: block;
          height: 1px;
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 100px;
          width: 100vw;
          z-index: -1;
        }
      }

      &:first-child {

        &::before {
          left: 50%;
          width: 50vw;
        }
      }

      &:last-child {

        &::before {
          left: 0;
          width: 50vw;
        }
      }
    }
  }
}

.timeline {

  &__slide {
    margin-bottom: 0;
    max-width: 1300px;
    position: relative;

    @include mq($bp-medium) {

      .col:first-child {
        z-index: 2;

        @include mq($bp-large) {

          &::before {
            background-color: _($colors, 'white');
            content: '';
            display: block;
            height: 200px;
            left: 0;
            position: absolute;
            width: 20px;
            z-index: -1;
          }
        }

        .tns-outer,
        .figure,
        .slideshow {
          width: calc(100% + 40px);
        }
      }
    }

    &__title {
      display: inline-block;
    }
  }
}
/*! purgecss end ignore */