$arrow: "%3Csvg viewBox='0 0 192 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z'/%3E%3C/svg%3E";
$arrowHover: "%3Csvg viewBox='0 0 192 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{_($colors, 'brand-2')}' d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z'/%3E%3C/svg%3E";

.main-menu {
  $self: &;

  justify-content: stretch;
  position: relative;

  @if $theme  !='buurblok' {
    @include mq(1280) {
      .col--navigation & {
        justify-content: center;
      }
    }

  }

  &__link {
    transition: color 200ms ease;
    font-weight: bold;

    @if $theme =='buurblok' {
      text-transform: uppercase;
    }

    @include mq($bp-mobilemenu, max) {
      text-transform: uppercase;
    }
  }

  &__item {
    flex: 0 0 100%;
    //position: relative;

    @include mq($bp-mobilemenu, max) {
      border-bottom: 1px solid _($colors, 'grey-200');
      margin-bottom: 10px;
      padding-bottom: 10px;
    }

    @include mq($bp-medium) {
      flex: 0 0 auto;
      display: inline-block;
    }

    &:hover,
    &--selected {

      #{$self}__link {
        color: _($colors, 'brand-2');
      }
    }
  }

  @include mq($bp-mobilemenu) {

    &__sub {
      left: 0;
      position: absolute;
      text-align: left;
      padding-left: 15px;

      &::before {
        background: _($colors, 'grey-200');
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 10px;
        transform: translateX(-50%);
        width: 200vw;
        z-index: -1;
      }

      &::after {
        background: _($colors, 'white');
        bottom: 0;
        box-shadow: 0 60px 40px rgba(_($colors, 'black'), .15);
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-50%);
        width: 200vw;
        z-index: -2;
      }
    }

    .trigger-enter {

      .sub-menu {
        display: flex;
        pointer-events: auto;
      }

      &--active {

        .sub-menu {
          opacity: 1;

          &__menu {

            li {
              margin-left: 0;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.sub-menu {

  a,
  .icon {
    transition: color, fill 100ms ease;
    font-weight: 700;
    font-size: 1.8rem;
  }

  a:hover,
  a.selected {
    color: _($colors, 'brand-2');

    .icon {
      fill: _($colors, 'brand-2');
    }
  }
}

@include mq($bp-mobilemenu, max) {

  .sub-menu {
    margin-bottom: 20px;

    &__menu {

      li:not(:last-child) {
        margin-bottom: 0 !important;
        /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

.sub_submenu {
  li {
    padding-left: 15px;

    &:first-of-type {
      display: none;
    }

    a {
      font-weight: normal;
      font-size: inherit;
    }
  }
}

@include mq($bp-mobilemenu) {
  .sub-menu {
    display: none;
    z-index: 3;

    .main-menu__item--has-submenu:hover & {
      display: flex;
    }

    &__menu {
      margin: 0;
      padding: 30px 0;

      @include mq($bp-mobilemenu) {
        display: flex;

        .list {
          min-width: 250px;

          li {
            padding: 10px 0;
            border-bottom: 1px solid _($colors, 'grey-200');
            position: static;
            margin-right: 0;

            a {
              font-weight: 700;
              font-size: 1.8rem;
              display: flex;
              align-items: center;
              justify-content: space-between;


            }

            &.has-submenu {
              a {
                &::after {
                  content: url("data:image/svg+xml, #{$arrow}");
                  display: block;
                  width: 9px;
                  height: 23px;
                }

                &:hover,
                &:focus {
                  &::after {
                    content: url("data:image/svg+xml, #{$arrowHover}");
                  }
                }
              }
            }

            ul.sub_submenu {
              position: absolute;
              top: 20px;
              bottom: 0;
              left: 100%;
              display: none;

              li {
                margin-left: 90px;
                min-width: 250px;
                border-bottom: 1px solid transparent;

                &:first-of-type {
                  display: block;
                  font-weight: bold;
                  font-size: 2.8rem;
                  line-height: 1.3;
                }

                a {
                  justify-content: flex-end;
                  flex-direction: row-reverse;
                  font-weight: normal;


                  span {
                    padding-left: 15px;
                  }

                  &::after {
                    content: url("data:image/svg+xml, #{$arrowHover}");
                  }
                }
              }
            }

            &:hover,
            &:focus,
            &:focus-within {
              ul.sub_submenu {
                display: block;
              }
            }
          }

          &:not(:last-child) {
            border-right: 1px solid _($colors, 'grey-200');
            margin-right: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}