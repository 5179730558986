.paging {
  box-sizing: border-box;
  width: 100%;

  li {
    box-sizing: border-box;
    display: inline-block;
    list-style: none;
    margin: 0 1px;
    user-select: none;

    @include mq($bp-medium) {
      border: 0;
    }

    a,
    span {

      &::after {
        display: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    a:hover {
      background-color: _($colors, 'grey-200');
      color: $color-text;
    }

    span {
      cursor: default;
      opacity: .6;
    }

    &.current {

      a {
        background: _($colors, 'brand-2');
        color: _($colors, 'white');
        cursor: default;
        font-weight: bold;
        text-decoration: none;
      }
    }

    &.jump {
      color: _($colors, 'grey-400');
    }

    &.previous,
    &.next,
    &.last {
      box-sizing: border-box;
      display: none;

      @include mq($bp-small) {
        display: inline-block;
      }
    }
  }
}
