.normal {
  font-weight: 300;
}

.strong,
strong {
  font-weight: 800;
}

.font-w300 { font-weight: 300; }
.font-w400 { font-weight: 400; }
.font-w700 { font-weight: 700; }
.font-w800 { font-weight: 800; }

.italic,
em {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

sub {
  font-size: 80%;
  vertical-align: sub;
}

sup {
  font-size: 80%;
  vertical-align: super;
}
