/*! purgecss start ignore */
.band--homepageslider {

  // .tns-ovh {
  //   @include mq($bp-medium) {
  //     padding-bottom: 80px;
  //   }

  //   @include mq($bp-large) {
  //     padding-bottom: 120px;
  //   }
  // }

  .tns-slide-active {

    .col:first-child {
      opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
      transform: translateY(0) !important; /* stylelint-disable-line declaration-no-important */
    }

    .figure img {
      opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
      top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .scroll-down {
      opacity: 1 !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.homepageslider {
  // border: 2px solid blue;
  display: block;
  position: relative;

  // .slider__item {
  //   border: 2px solid red;
  // }

  .scroll-down {
    opacity: 0;
    transition: opacity 1000ms 250ms;
    transition-delay: 1000ms;
  }

  &__text {
    box-sizing: border-box;
    opacity: 0;
    position: relative;
    transform: translateY(40px);
    transition: all 1000ms 250ms;

    .tns-slide-active & {
      opacity: 1;
      transform: translateY(0);
    }

    @include mq($bp-medium) {
      min-height: calc(50vw - 20px);
    }

    @include mq($bp-large) {
      min-height: calc(33vw - 10px);
    }

    @include mq(1600) {
      min-height: 520px;
    }
  }

  &__slide {
    // border: 2px solid pink;
    max-width: 1600px;
    position: relative;

    // .row {
    //   border: 1px solid red;

    //   .col {
    //     border: 1px solid pink;
    //   }
    // }

    .col {
      position: relative;
      transform: perspective(1000px);
      transform-style: preserve-3d;
      z-index: 2;

      &:first-child {
        opacity: 0;
        pointer-events: none;
        transform: translateY(100px);
        transition: opacity 500ms, transform 500ms;

        // @include mq($bp-medium) {
        //   @include mq($bp-large, max) {
        //     padding-bottom: 100px;
        //   }
        // }

        a {
          pointer-events: auto;
        }
      }

      &:last-child {
        z-index: 1;
      }

      .figure {
        display: block;
        max-width: 100%;

        img {
          opacity: 0;
          position: relative;
          top: 60px;
          transition: opacity 1000ms, top 500ms;
          width: 100%;
        }

        @include mq($bp-medium, max) {

          img {
            box-shadow: none !important; /* stylelint-disable-line declaration-no-important */
            top: 0 !important; /* stylelint-disable-line declaration-no-important */
          }

          &:nth-child(2),
          &:nth-child(3) {
            float: left;
            width: 50%;
          }
        }

        @include mq($bp-medium) {
          transform: perspective(1000px);
          transform-style: preserve-3d;

          &:nth-child(1) {
            display: block;
            margin: 0 0 240px -40px;
            max-width: calc(100% + 40px);
            position: relative;
            top: 40px;
            width: calc(100% + 40px);
            z-index: 2;

            @include mq($bp-large) {
              margin: 0 120px 300px 40px;
              max-width: 100%;
              top: 0;
              width: calc(100% - 160px);
            }

            img {
              transition-delay: 500ms;
            }
          }

          &:nth-child(2) {
            bottom: -40px;
            left: -150px;
            max-width: 30vw;
            position: absolute;
            z-index: 3;

            @include mq($bp-large) {
              bottom: 0;
              left: -100px;
              max-width: 100%;
              width: 400px;
              z-index: 1;
            }

            @include mq($bp-xlarge) {
              left: -200px;
              width: 500px;
            }

            img {
              transform: translateZ(-120px);
              transition-delay: 1s;
            }
          }

          &:nth-child(3) {
            bottom: auto;
            max-width: 25vw;
            position: absolute;
            right: 40px;
            top: 45%;
            width: 420px;
            z-index: 3;

            @include mq($bp-large) {
              bottom: 100px;
              max-width: 25vw;
              top: auto;
            }

            img {
              transform: translateZ(120px);
              transition-delay: 750ms;
            }
          }
        }
      }
    }
  }

  &__controls {
    color: _($colors, 'white');

    a {
      border-radius: 3px;

      &:hover {
        background: _($colors, 'white');

        svg {
          fill: _($colors, 'brand-2');
        }
      }
    }
  }
}
/*! purgecss end ignore */