.icon {
  display: inline-block;
  height: 2px;
  position: relative;
  top: -1px;
  user-select: none;
  vertical-align: middle;
  width: 16px;

  > svg {
    // transition: all $transition-duration $transition-timing;
    display: block;
    max-height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 96%;
  }

  $icon-sizes: 16, 20, 32, 44, 72, 96;
  $icons: (
    1: nth($icon-sizes, 1),
    2: nth($icon-sizes, 2),
    3: nth($icon-sizes, 3),
    4: nth($icon-sizes, 4),
    5: nth($icon-sizes, 5),
    6: nth($icon-sizes, 6)
  );

  @each $icon, $size in $icons {

    &-size#{$icon} {
      height: $size + px;
      width: $size + px;

      > svg {
        max-height: $size + px;
      }
    }
  }

  &-logo {
    height: auto;
    max-width: 240px;
    width: 100%;

    > svg {
      max-height: inherit;
      width: 100%;
    }
  }

  &-mobile {
    height: nth($icon-sizes, 3) + px;
    width: nth($icon-sizes, 3) + px;

    > svg {
      max-height: nth($icon-sizes, 3) + px;

      g {
        stroke: _($colors, 'grey-800');

        .bc-grey-800 & {
          stroke: _($colors, 'white');
        }
      }
    }
  }

  &--social {

    .bc-brand-1 & {

      .fill-white {
        fill: _($colors, 'brand-1');
      }

      .fill-brand-1 {
        fill: _($colors, 'white');
      }
    }
  }
}
