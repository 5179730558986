/*! purgecss start ignore */

.filters {
  $self: &;

  &__toggle {
    display: none;
  }

  &--more {

    #{$self}__toggle {
      display: block;
    }
  }

  @include mq($bp-xsmall) {
    @include mq($bp-large, max) {

      &--project {
        flex: 1 1 50%;
        max-width: 50%;
      }
    }
  }

  @include mq($bp-medium) {
    @include mq($bp-large, max) {

      &--project {
        flex: 1 1 33.33%;
        max-width: 33.33%;
      }
    }
  }
}

.col--projectfilters {
  @include mq($bp-medium, max) {

    .projectfilterstitle {
      color: _($colors, 'brand-1') !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  @include mq($bp-xsmall) {
    @include mq($bp-large, max) {

      .toggle__content {
        align-items: stretch;
        display: flex !important; /* stylelint-disable-line declaration-no-important */
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }
  }
}

/*! purgecss end ignore */