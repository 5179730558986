.infographic {

  .stroke {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .stroke-brand {
    stroke: _($colors, 'brand-1');
  }

  .fill-brand {
    fill: _($colors, 'brand-1');
  }

  .fill-none {
    fill: none;
  }

  .stroke-1 {
    stroke: _($colors, 'grey-800');
  }

  .stroke-black {
    stroke: _($colors, 'grey-800');
  }

  .fill-1 {
    fill: _($colors, 'grey-200');
  }

  .fill-2 {
    fill: _($colors, 'grey-800');
  }

  .stroke-dots {
    animation: dash 2s linear;
    animation-iteration-count: infinite;
    stroke-dasharray: 0 5;
    stroke-width: 2;
  }

  @keyframes dash {

    to {
      stroke-dashoffset: 10;
    }
  }

  .bc-grey-800 & {

    .stroke-1 {
      stroke: _($colors, 'grey-200');
    }

    // .stroke-black {
    //   stroke: _($colors, 'grey-800');
    // }

    .fill-1 {
      fill: _($colors, 'grey-800');
    }

    .fill-2 {
      fill: _($colors, 'grey-200');
    }
  }
}
