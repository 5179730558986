@import 'variables';

.c {
  @each $name, $color in $colors {

    &-#{$name} {
      color: $color;
    }
  }
}

.bc {
  @each $name, $color in $colors {

    &-#{$name} {
      background-color: $color;
    }

    &-#{$name}-o {
      background-color: rgba(darken($color, 5), .9);
    }
  }
}

.fill {
  @each $name, $color in $colors {

    &-#{$name} {
      fill: $color;
    }
  }
}

.stroke {
  @each $name, $color in $colors {

    &-#{$name} {
      stroke: $color;
    }
  }
}

.bm {
  @each $name
    in (
      normal,
      multiply,
      screen,
      overlay,
      darken,
      lighten,
      color-dodge,
      color-burn,
      hard-light,
      soft-light,
      difference,
      exclusion,
      hue,
      saturation,
      color,
      luminosity
    ) {

    &-#{$name} {
      mix-blend-mode: $name;
    }
  }
}
