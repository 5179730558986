.button {
  @include font-size(nth($font-sizes, 2));

  box-sizing: border-box;
  color: _($colors, 'white');
  cursor: pointer;
  display: inline-block;
  font-family: $font-default;
  //  font-weight: 800;
  // line-height: 1.25;
  min-height: 52px;
  outline: none;
  overflow: hidden;
  padding: ($gutter * 0.5) $gutter;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all $transition-duration $transition-timing;
  user-select: none;

  @if $theme == 'buurblok' {
    background-color: _($colors, 'brand-3');
    border: 2px solid _($colors, 'brand-3');
  }

  @else {
    background-color: _($colors, 'brand-1');
    border: 2px solid _($colors, 'brand-1');
  }

  @include mq($bp-small, max) {
    // display: block;
    width: 100%;
  }

  &:hover {
    @if $theme == 'buurblok' {
      background-color: _($hover-colors, 'brand-3');
      box-shadow: 0 5px 36px rgba(_($colors, 'black'), .2);
    }

    @else {
      background-color: _($hover-colors, 'brand-1');
      box-shadow: 0 5px 36px rgba(_($colors, 'black'), .2);
    }
  }

  &--outline {
    background-color: _($colors, 'white');
    color: _($colors, 'brand-1');

    &:hover {
      background-color: _($colors, 'white');
      color: _($hover-colors, 'brand-1');
    }
  }

  &--small {
    @include font-size(nth($font-sizes, 1));

    font-weight: bold;
    min-height: 24px;
    padding: $gutter * 0.25 $gutter * 0.5;
  }

  &--large {
    max-width: 405px;
    padding: $gutter;
    width: 100%;
  }

  &--white {
    background-color: _($colors, 'white');
    border-color: _($colors, 'white');
    color: _($colors, 'grey-500');

    &:hover {
      background-color: _($colors, 'white');
      border-color: _($colors, 'white');
      color: _($colors, 'brand-1');
    }
  }

  @for $i from 1 through 10 {

    &--brand-#{$i} {
      background-color: _($colors, 'brand-#{$i}');
      border-color: _($colors, 'brand-#{$i}');

      &:hover {
        background-color: _($hover-colors, 'brand-#{$i}');
        border-color: _($hover-colors, 'brand-#{$i}');
      }
    }

    &--brand-#{$i}.button--outline {
      background-color: _($colors, 'white');
      color: _($colors, 'brand-#{$i}');
    }
  }

  &--block {
    display: block;
    padding-left: $gutter;
    padding-right: $gutter;
  }

  &.no-shadow {
    box-shadow: none;

    &::after {
      display: none;
    }
  }

  &.no-border {
    border: 0;
  }
}

.zoom-in {
  transition: all $transition-duration $transition-timing;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }
}

.move-up {
  transition: all $transition-duration $transition-timing;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}
