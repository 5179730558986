.gallery {
  max-width: 1300px;

  .tile {
    box-sizing: border-box;
    flex: 0 0 50%;
    max-width: 50%;

    @include mq($bp-small) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    a {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding-bottom: 100%;
      position: relative;
      transition: transform $transition-duration $transition-timing;

      &:hover {
        transform: translateY(-5px);
      }
    }

    &--video {
      flex: 0 0 100%;
      max-width: 100%;

      @include mq($bp-small) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      a {
        padding-bottom: 50%;

        @include mq($bp-small) {
          padding-bottom: 45%;
        }
      }
    }

    .figure {
      box-sizing: border-box;
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
