.autocomplete {
  background: _($colors, 'white');
  border: 1px solid _($colors, 'grey-200');
  border-radius: 4px;
  box-shadow: 5px 5px 10px 5px rgba(_($colors, 'black'), .2);
  position: absolute;
  width: 100%;

  &__holder {
    position: relative;
    z-index: 5;
  }

  &__result {
    border-bottom: 1px solid _($colors, 'grey-200');

    &:last-child {
      border-bottom: 0;
    }

    a {
      display: flex !important; /* stylelint-disable-line declaration-no-important */
      justify-content: space-between;
      text-decoration: none;

      &::after {
        display: none !important; /* stylelint-disable-line declaration-no-important */
      }

      &:hover {
        background-color: _($colors, 'grey-200');
      }
    }
  }

  .button--outline {
    border: 1px solid _($colors, 'brand-1');
    font-size: 80%;
  }
}
