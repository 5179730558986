.card {
  &--project {
    max-width: 480px;
    text-decoration: none;
 
    .col {
      width: 100%;

      &:last-child {
        display: grid;
        grid-template-rows: subgrid;
        margin-top: auto;
      }
    }

    .figure--no-image {
      background-color: _($colors, 'grey-200');
      display: block;
      padding-bottom: 62%;
      text-decoration: none;
      width: 100%;
    }
  }

  &--content {
    position: relative;
    z-index: 1;
  }

  &--color {
    transform-style: preserve-3d;

    &__button {
      transform: perspective(1000px) translateZ(20px);
    }
  }

  .figure {
    overflow: hidden;
  }

  img {
    
    transition: transform 0.3s ease-in-out; 
  }

 
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    img {
      transform: scale(1.1); // Scale up the image
    }

   
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
}
 