.band--footer {
  @if $theme == 'buurblok' {

    &.bc-brand-1 {
      background-color: _($colors, 'brand-3');
    }
  }

  @include mq($bp-large, max) {

    .flx {
      flex-wrap: wrap !important; /* stylelint-disable-line declaration-no-important */
    }

    .col--logo {
      flex: 0 0 100%;
      text-align: center;
      width: 100%;
    }

    .col--footermenu {
      text-align: center;

      li.break {
        display: block;
        height: 1px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}
