/*! purgecss start ignore */
$headermaxwidth: 1600;

:root {
	--swiper-theme-color: #fff !important;
}

.row--header {
	max-width: $headermaxwidth + px;
}

.col--header-text {
	position: relative;
	z-index: 2;
}

.header-text {
	@include mq($bp-large) {
		min-height: 33vw;
	}

	@include mq($headermaxwidth) {
		min-height: ($headermaxwidth * 0.01 * 33) + px;
	}
}

.col--header-image {
	order: -1;
	position: relative;
	z-index: 1;

	@include mq($bp-large) {
		order: 99;

		img {
			left: -60px;
			max-width: calc(
				100% + 60px
			) !important; /* stylelint-disable-line declaration-no-important */
			position: relative;
			width: calc(
				100% + 60px
			) !important; /* stylelint-disable-line declaration-no-important */
		}
	}

	.swiper {
		left: -60px;
		width: calc(100% + 60px);
		img {
			width: 1600px;
			max-height: 450px;
			object-fit: cover;
			left: 0;
			width: 100%;
		}
	}
	.swiper-pagination-bullet {
    border: 2px solid #fff;
    height: 12px;  
    width: 12px;
		&-active {
			background: _($colors, 'brand-2');
			opacity: var(--swiper-pagination-bullet-opacity, 1);
		}
	}
  
}

.col--header-image, .project-header__image {
  opacity: 0; 
  transform: translateY(40px);
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-image {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.col--header-text, .project-header__text {
  animation: slide-up 0.8s ease-out forwards;
}

.col--header-image, .project-header__image {
  animation: slide-up-image 1s ease-out forwards 0.5s;
}
/*! purgecss end ignore */