select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22#{rgba(_($colors, "grey-800"), .99)}%22%20d%3D%22M7.406%207.828l4.594%204.594%204.594-4.594%201.406%201.406-6%206-6-6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-position: 98% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 0 ($gutter * 1.5) 0 ($gutter * 0.5);

  /* stylelint-disable */
  _:-ms-fullscreen, :root  & {
    padding: 0 0 0 $gutter * 0.5;
  }
  /* stylelint-enable */

  &:hover {
    background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22#{rgba(_($colors, "grey-800"), .99)}%22%20d%3D%22M7.406%207.828l4.594%204.594%204.594-4.594%201.406%201.406-6%206-6-6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  }

  &.interacted {

    &:required:valid {
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22#{rgba(_($colors, "grey-500"), .99)}%22%20d%3D%22M7.406%207.828l4.594%204.594%204.594-4.594%201.406%201.406-6%206-6-6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    }

    &:required:invalid {
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22#{rgba(_($colors, "status-error"), .99)}%22%20d%3D%22M7.406%207.828l4.594%204.594%204.594-4.594%201.406%201.406-6%206-6-6z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
    }
  }
}
