@use "sass:math";

.list {

  &--numbers {
    list-style: decimal;

    li {
      color: _($colors, 'brand-2');
      margin-left: $gutter * 2;
      padding-left: math.div($gutter, 1);
    }
  }

  &--bullets {
    list-style-type: disc;

    li {
      color: _($colors, 'brand-2');
      margin-left: $gutter * 2;
      padding-left: $gutter * 0.5;
    }
  }

  &--icons {

    li {
      margin-bottom: $gutter * 0.5;
      padding-left: $gutter * 2;
      position: relative;

      a {
        display: block;
        left: - $gutter * 2;
        padding-left: $gutter * 2;
        position: relative;

        .icon-holder {
          top: 0;
        }
      }

      .icon-holder {
        display: block;
        height: 28px;
        left: 0;
        position: absolute;
        top: -2px;
        width: 28px;
      }

      .icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--inline {

    li {
      display: inline-block;
    }
  }
}
