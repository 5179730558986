$line-sizes: 1, 1.25, 1.5, 1.75, 2;
$line-heights: (
  1: nth($line-sizes, 1),
  2: nth($line-sizes, 2),
  3: nth($line-sizes, 3),
  4: nth($line-sizes, 4),
  5: nth($line-sizes, 5)
);

.lh {
  @each $height, $line-height in $line-heights {

    &#{$height} {
      line-height: $line-height;
    }
  }
}
