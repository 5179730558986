.form {
  max-width: $form-item-max-width;

  &--search {
    max-width: none;

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    input[type='tel'],
    select,
    textarea {
      max-width: none;
    }
  }
}

.search-form {
  // max-width: 500px;
  position: relative;
  width: 100%;

  &.search-form &__field {
    @include font-size(16);

    background-color: _($colors, 'grey-200');
    border: 0;
    // border-bottom: 1px solid _($colors, 'grey-400');
    border-radius: 0;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    padding-right: 10px;

    &::placeholder {
      color: _($colors, 'brand-1');
      font-style: italic;
      opacity: .8;
    }
  }

  &__icon {
    align-content: center;
    bottom: 1px;
    display: flex;
    height: 100%;
    left: 10px;
    position: absolute;
    top: 0;
  }
}

.project-search {
  @include mq($bp-small) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    input {
      max-width: calc(100% - 210px);
    }

    button {
      margin-top: 0;
      width: 200px;
    }
  }
}
